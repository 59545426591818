import React from "react";
import { useWindow } from "../../helpers/useWindow";
import "./ReportSample.css";

const ReportSample = () => {
  let layer = useWindow();

  let majalengka;
  let kpi;
  let cirebon;
  let kejari;
  if (layer >= 821) {
    majalengka = "/assets/Image/majalengka.png";
    kpi = "/assets/Image/kpi.png";
    cirebon = "/assets/Image/cirebon.png";
    kejari = "/assets/Image/kejari.png";
  } else {
    majalengka = "/assets/Mobile/majalengka.png";
    kpi = "/assets/Mobile/kpi.png";
    cirebon = "/assets/Mobile/cirebon.png";
    kejari = "/assets/Mobile/kejari.png";
  }
  return (
    <div className="container">
      <div className="default-title report-sample-title2">
        <span>Report Sample</span>
      </div>
      <div className="report-sample-content-container">
        <div className="report-sample-content">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://tinyurl.com/2b538l2c"
            className="portfolio-item"
          >
            <img className="zoom" src={majalengka} alt="" />
          </a>
          <div className="report-sample-title">
            <span>Dinas Pariwisata dan</span>
            <span>Budaya Majalengka</span>
          </div>
        </div>
        <div className="report-sample-content">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://tinyurl.com/4tmxuh3c"
            className="portfolio-item"
          >
            <img className="zoom" src={kpi} alt="" />
          </a>
          <div className="report-sample-title">
            <span>Komisi Penyiaran</span>
            <span>Indonesia</span>
          </div>
        </div>
        <div className="report-sample-content">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://tinyurl.com/3bka4y26"
            className="portfolio-item"
          >
            <img className="zoom" src={cirebon} alt="" />
          </a>
          <div className="report-sample-title">
            <span>Dinas Pariwisata dan</span>
            <span>Budaya Cirebon</span>
          </div>
        </div>
        <div className="report-sample-content">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://tinyurl.com/3umu975p"
            className="portfolio-item"
          >
            <img className="zoom" src={kejari} alt="" />
          </a>
          <div className="report-sample-title">
            <span>Kejaksaan Tinggi</span>
            <span>Jawa Barat</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportSample;
