import React from "react";
import "./SocialMediaAssets.css";
import { useWindow } from "../../helpers/useWindow";

const SocialMediaAssets = () => {
  let layer = useWindow();

  let sosmed1;
  let sosmed2;
  let sosmed3;
  let monitoring;
  if (layer >= 768) {
    sosmed1 = "/assets/Image/sosmed1.png";
    sosmed2 = "/assets/Image/sosmed2.png";
    sosmed3 = "/assets/Image/sosmed3.png";
    monitoring = "/assets/Image/monitoring.png";
  } else {
    sosmed1 = "/assets/Mobile/sosmed1.png";
    sosmed2 = "/assets/Mobile/sosmed2.png";
    sosmed3 = "/assets/Mobile/sosmed3.png";
    monitoring = "/assets/Mobile/monitoring.png";
  }

  return (
    <div id="sosmed-assets" className="sosmed-assets-container">
      <div className="sosmed-monitor-title">
        <span>Social Media Assets & Audience Profiling</span>
      </div>
      <div id="smm" className="sosmed-monitoring-container">
        <div className="sosmed-content-container">
          <div className="graphic-monitoring">
            <img src={sosmed1} alt="tabel" />
            <img src={sosmed2} alt="tabel" />
            <img src={sosmed3} alt="tabel" />
          </div>
        </div>
        <div className="real-time-monitoring">
          <div className="sosmed-monitor-title2">
            <span>real time monitoring</span>
          </div>
          <div className="real-time-monitoring-image">
            <img src={monitoring} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialMediaAssets;
