// import logo from "./logo.svg";
import "./App.css";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import Pendahuluan from "./components/Pendahuluan";
import Kenapa from "./components/Kenapa";
import Layanan from "./components/Layanan";
import Portfolio from "./components/Portfolio";
import SocialMediaAssets from "./components/SocialMediaAssets";
import Klien from "./components/Klien";
import Kontak from "./components/Kontak";
import Ekosistem from "./components/Ekosistem";
import TvProgram from "./components/TvProgram";
import Offair from "./components/Offair";
import DigitalPublishing from "./components/DigitalPublishing";
import Reach from "./components/Reach";
import SocialNetworkAnalysis from "./components/SocialNetworkAnalysis";
import WebDevelopment from "./components/WebDevelopment";
import ReportSample from "./components/ReportSample";
import DifferentAngle from "./components/DifferentAngle";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Hero />
      <Pendahuluan />
      <Ekosistem />
      <TvProgram />
      <Offair />
      <DigitalPublishing />
      {/* <div id="layanan" style={{ backgroundColor: "#009DAE" }}>
        <img src="./assets/Image/Layanan.png" alt="" />
      </div> */}
      <WebDevelopment />
      {/* <img src="./assets/Image/Different.png" alt="" /> */}
      <DifferentAngle />
      <Portfolio />
      <SocialMediaAssets />
      <SocialNetworkAnalysis />
      <Reach />
      <Kenapa />
      <Layanan />
      <Klien />
      <ReportSample />
      <Kontak />
      <Footer />
    </div>
  );
}

export default App;
