import React from "react";
import "./DifferentAngle.css";
import { useWindow } from "../../helpers/useWindow";

const DifferentAngle = () => {
  let layer = useWindow();

  let image;
  if (layer >= 820) {
    image = "/assets/Image/angle.png";
  } else {
    image = "/assets/Mobile/angle.png";
  }
  return (
    <div className="different-angle-container">
      <div className="different-angle-title">
        <div className="different-angle-title-content">
          <span>Different </span>
          <span>people, </span>
          <span>different </span>
          <span>angle</span>
        </div>
      </div>
      <div className="different-angle-image">
        <img src={image} alt="" />
      </div>
    </div>
  );
};

export default DifferentAngle;
