import React from "react";
import "./DigitalPublishing.css";

const DigitalPublishing = () => {
  return (
    // <div className="container">
    <div id="digital-publishing" className="digital-container">
      <div className="digital-title-container">
        <span className="default-title">digital publishing</span>
      </div>
      <div className="digital-content-container">
        <div className="digital-content-list-container">
          <div style={{ height: "9rem" }}>
            <div className="digital-content-logo">
              <img src="./assets/Logo/logo-detik.png" alt="" />
            </div>
          </div>
          <div className="digital-content-website">
            <span>https://www.detik.com</span>
          </div>
          <div className="digital-content-desc">
            <span>
              Detikcom (stylized as detikcom) is an Indonesian digital media
              company owned by CT Corp subsidiary Trans Media. Detikcom is an
              online news portal and publishes breaking news. The portal is
              consistently ranked among Indonesia's 10 most-visited websites and
              is among the top 250 in the world.[1] It receives approximately
              180 million visits per day.
            </span>
          </div>
          <div className="digital-content-footer">
            <span>UV 1000</span>
          </div>
        </div>
        <div className="digital-content-list-container">
          <div style={{ height: "9rem" }}>
            <div className="digital-content-logo">
              <img src="./assets/Logo/logo-cxo.png" alt="" />
            </div>
          </div>
          <div className="digital-content-website">
            <span>https://www.cxomedia.id</span>
          </div>
          <div className="digital-content-desc">
            <span>
              Narrating Stories that Empower. CXO Media is Transmedia’s newest
              digital media that was born on Youtube in August 2020. We focus on
              crafting authentic engaging content for the young generation
              through inspiring, insightful, and entertaining contents.
            </span>
          </div>
          <div className="digital-content-footer">
            <span>UV 1000</span>
          </div>
        </div>
        <div className="digital-content-list-container">
          <div style={{ height: "9rem" }}>
            <div className="digital-content-logo">
              <img src="./assets/Logo/logo-insertlive.png" alt="" />
            </div>
          </div>
          <div className="digital-content-website">
            <span>https://www.insertlive.com</span>
          </div>
          <div className="digital-content-desc">
            <span>
              Insert (singkatan dari Informasi Selebriti, digayakan sebagai
              !nsert) adalah program berita hiburan yang ditayangkan di Trans
              TV. Acara ini disiarkan sejak tanggal 7 Juli 2003[1] dengan
              membahas informasi kehidupan selebritas dan kejadian viral di
              dunia maya lekat dengan tagline Where Gossip Can Be Fun!
            </span>
          </div>
          <div className="digital-content-footer">
            <span>UV 1000</span>
          </div>
        </div>
        {/* <div className="digital-content-list-container">
          <div style={{ height: "9rem" }}>
            <div className="digital-content-logo">
              <img src="./assets/Logo/logo-beautynesia.png" alt="" />
            </div>
          </div>
          <div className="digital-content-website">
            <span>https://www.beautynesia.id</span>
          </div>
          <div className="digital-content-desc">
            <span>
              Di Beautynesia, kami percaya, kecantikan tak melulu soal fisik.
              Cantik adalah ketika perempuan merasa utuh, menjadi versi terbaik
              dari diri mereka. Beautynesia memiliki visi menjadi platform media
              terdepan yang menulis soal trend, gaya hidup, fashion, influencer
              serta isu-isu sosial perempuan.
            </span>
          </div>
          <div className="digital-content-footer">
            <span>UV 1000</span>
          </div>
        </div>
        <div className="digital-content-list-container">
          <div style={{ height: "9rem" }}>
            <div className="digital-content-logo">
              <img src="./assets/Logo/logo-haibunda.png" alt="" />
            </div>
          </div>
          <div className="digital-content-website">
            <span>https://www.haibunda.com</span>
          </div>
          <div className="digital-content-desc">
            <span>
              Menjadi orang tua, khususnya bunda, tidaklah mudah. Perlu belajar
              untuk bisa mendidik dan merawat anak dengan baik. Seiring dengan
              perubahan zaman dan perkembangan teknologi informasi, tantangan
              menjadi orang tua pun semakin bertambah. Tidak hanya itu, begitu
              banyak informasi dan mitos-mitos yang beredar seputar permasalahan
              bunda.
            </span>
          </div>
          <div className="digital-content-footer">
            <span>UV 1000</span>
          </div>
        </div>
        <div className="digital-content-list-container">
          <div style={{ height: "9rem" }}>
            <div className="digital-content-logo">
              <img src="./assets/Logo/logo-femaledaily.png" alt="" />
            </div>
          </div>
          <div className="digital-content-website">
            <span>https://www.femaledaily.com</span>
          </div>
          <div className="digital-content-desc">
            <span>
              FEMALE DAILY is all about empowering women through the happiness
              from small acts of joy, like opening a new tube of lipstick.
              FEMALE DAILY is about YOU. We want to bring out the inner beauty
              already engrained in women of all sizes, shapes, and ages, and
              help you share that with the world.
            </span>
          </div>
          <div className="digital-content-footer">
            <span>UV 1000</span>
          </div>
        </div>
        <div className="digital-content-list-container">
          <div style={{ height: "9rem" }}>
            <div className="digital-content-logo">
              <img src="./assets/Logo/logo-cnn.png" alt="" />
            </div>
          </div>
          <div className="digital-content-website">
            <span>https://www.cnnindonesia.com</span>
          </div>
          <div className="digital-content-desc">
            <span>
              CNN Indonesia adalah sebuah jaringan televisi berita digital
              gratis dan berbayar (di Transvision, IndiHome dan UseeTV), serta
              situs berita milik Trans Media dengan mengambil lisensi nama CNN
              dari Warner Bros. Discovery (melalui Warner Bros. Discovery
              Asia-Pacific).[3] Saluran ini disiarkan pertama kalinya pada
              Senin, 17 Agustus 2015. Peluncuran utamanya berlangsung sejak 15
              Desember 2015 dalam rangka memperingati ulang tahun Trans Media
              yang ke-14.
            </span>
          </div>
          <div className="digital-content-footer">
            <span>UV 1000</span>
          </div>
        </div>
        <div className="digital-content-list-container">
          <div style={{ height: "9rem" }}>
            <div className="digital-content-logo">
              <img src="./assets/Logo/logo-cnbc.png" alt="" />
            </div>
          </div>
          <div className="digital-content-website">
            <span>https://www.cnbcindonesia.com</span>
          </div>
          <div className="digital-content-desc">
            <span>
              CNBC Indonesia adalah sebuah stasiun televisi dan situs berita
              bisnis milik Trans Media dengan mengambil lisensi nama CNBC dari
              NBCUniversal, divisi dari Comcast (melalui NBCUniversal
              International Networks). Portal berita CNBC Indonesia diluncurkan
              pada 8 Februari 2018,[2] dan siaran televisi mulai mengudara pada
              10 Oktober 2018.[3] Mulai 24 Januari 2020, siaran CNBC Indonesia
              dapat disaksikan secara gratis melalui DVB-T2. UV 1000
            </span>
          </div>
          <div className="digital-content-footer">
            <span>UV 1000</span>
          </div>
        </div> */}
      </div>
      <div className="digital-content-container">
        <div className="digital-content-list-container">
          <div style={{ height: "9rem" }}>
            <div className="digital-content-logo">
              <img src="./assets/Logo/logo-beautynesia.png" alt="" />
            </div>
          </div>
          <div className="digital-content-website">
            <span>https://www.beautynesia.id</span>
          </div>
          <div className="digital-content-desc">
            <span>
              Di Beautynesia, kami percaya, kecantikan tak melulu soal fisik.
              Cantik adalah ketika perempuan merasa utuh, menjadi versi terbaik
              dari diri mereka. Beautynesia memiliki visi menjadi platform media
              terdepan yang menulis soal trend, gaya hidup, fashion, influencer
              serta isu-isu sosial perempuan.
            </span>
          </div>
          <div className="digital-content-footer">
            <span>UV 1000</span>
          </div>
        </div>
        <div className="digital-content-list-container">
          <div style={{ height: "9rem" }}>
            <div className="digital-content-logo">
              <img src="./assets/Logo/logo-haibunda.png" alt="" />
            </div>
          </div>
          <div className="digital-content-website">
            <span>https://www.haibunda.com</span>
          </div>
          <div className="digital-content-desc">
            <span>
              Menjadi orang tua, khususnya bunda, tidaklah mudah. Perlu belajar
              untuk bisa mendidik dan merawat anak dengan baik. Seiring dengan
              perubahan zaman dan perkembangan teknologi informasi, tantangan
              menjadi orang tua pun semakin bertambah. Tidak hanya itu, begitu
              banyak informasi dan mitos-mitos yang beredar seputar permasalahan
              bunda.
            </span>
          </div>
          <div className="digital-content-footer">
            <span>UV 1000</span>
          </div>
        </div>
        <div className="digital-content-list-container">
          <div style={{ height: "9rem" }}>
            <div className="digital-content-logo">
              <img src="./assets/Logo/logo-femaledaily.png" alt="" />
            </div>
          </div>
          <div className="digital-content-website">
            <span>https://www.femaledaily.com</span>
          </div>
          <div className="digital-content-desc">
            <span>
              FEMALE DAILY is all about empowering women through the happiness
              from small acts of joy, like opening a new tube of lipstick.
              FEMALE DAILY is about YOU. We want to bring out the inner beauty
              already engrained in women of all sizes, shapes, and ages, and
              help you share that with the world.
            </span>
          </div>
          <div className="digital-content-footer">
            <span>UV 1000</span>
          </div>
        </div>
      </div>
      <div className="digital-content-container">
        <div className="digital-content-list-container">
          <div style={{ height: "9rem" }}>
            <div className="digital-content-logo">
              <img src="./assets/Logo/logo-cnn.png" alt="" />
            </div>
          </div>
          <div className="digital-content-website">
            <span>https://www.cnnindonesia.com</span>
          </div>
          <div className="digital-content-desc">
            <span>
              CNN Indonesia adalah sebuah jaringan televisi berita digital
              gratis dan berbayar (di Transvision, IndiHome dan UseeTV), serta
              situs berita milik Trans Media dengan mengambil lisensi nama CNN
              dari Warner Bros. Discovery (melalui Warner Bros. Discovery
              Asia-Pacific).[3] Saluran ini disiarkan pertama kalinya pada
              Senin, 17 Agustus 2015. Peluncuran utamanya berlangsung sejak 15
              Desember 2015 dalam rangka memperingati ulang tahun Trans Media
              yang ke-14.
            </span>
          </div>
          <div className="digital-content-footer">
            <span>UV 1000</span>
          </div>
        </div>
        <div className="digital-content-list-container">
          <div style={{ height: "9rem" }}>
            <div className="digital-content-logo">
              <img src="./assets/Logo/logo-cnbc.png" alt="" />
            </div>
          </div>
          <div className="digital-content-website">
            <span>https://www.cnbcindonesia.com</span>
          </div>
          <div className="digital-content-desc">
            <span>
              CNBC Indonesia adalah sebuah stasiun televisi dan situs berita
              bisnis milik Trans Media dengan mengambil lisensi nama CNBC dari
              NBCUniversal, divisi dari Comcast (melalui NBCUniversal
              International Networks). Portal berita CNBC Indonesia diluncurkan
              pada 8 Februari 2018,[2] dan siaran televisi mulai mengudara pada
              10 Oktober 2018.[3] Mulai 24 Januari 2020, siaran CNBC Indonesia
              dapat disaksikan secara gratis melalui DVB-T2. UV 1000
            </span>
          </div>
          <div className="digital-content-footer">
            <span>UV 1000</span>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default DigitalPublishing;
