import React from "react";
import "./Reach.css";

const Reach = () => {
  return (
    <div id="reach" className="reach-container">
      <div className="reach-subtitle">
        <span>we reach more than</span>
      </div>
      <div className="reach-title">
        <span>70.000.000 audiences</span>
      </div>
      <div className="reach-subtitle">
        <span>everyday</span>
      </div>
    </div>
  );
};

export default Reach;
