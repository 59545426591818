import React from "react";
import "./Navbar.css";

const Navbar = () => {
  return (
    <>
      <div className="navbar">
        {/* <div className="nav-item">
          <Navitem />
        </div> */}
        <div className="navbar-container container">
          <input type="checkbox" name="" id="" />
          <div className="hamburger-lines">
            <span className="line line1"></span>
            <span className="line line2"></span>
            <span className="line line3"></span>
          </div>
          <div className="menu-items">
            <li className="navitem">
              <a
                data-replace="Pendahuluan"
                href="#pendahuluan"
                className="title"
              >
                <span>Pendahuluan</span>
              </a>
            </li>
            <li className="navitem">
              <a
                data-replace="Ekosistem Komunikasi 360"
                href="#ekosistem"
                className="title"
              >
                <span>Ekosistem Komunikasi 360</span>
              </a>
            </li>
            <li className="navitem">
              <a data-replace="tv-program" href="#tv-program" className="title">
                <span>TV Program</span>
              </a>
            </li>
            <li className="navitem">
              <a data-replace="offair" href="#offair" className="title">
                <span>Offair Activations & Exhibition</span>
              </a>
            </li>
            <li className="navitem">
              <a
                data-replace="digital-publishing"
                href="#digital-publishing"
                className="title"
              >
                <span>Digital Publishing</span>
              </a>
            </li>
            <li className="navitem">
              <a data-replace="web-dev" href="#web-dev" className="title">
                <span>Web Development</span>
              </a>
            </li>
            <li className="navitem">
              <a
                data-replace="sosmed-content"
                href="#sosmed-content"
                className="title"
              >
                <span>Social Media Content Production</span>
              </a>
            </li>
            <li className="navitem">
              <a
                data-replace="sosmed-assets"
                href="#sosmed-assets"
                className="title"
              >
                <span>Social Media Assets & Audience Profiling</span>
              </a>
            </li>
            <li className="navitem">
              <a
                data-replace="social-network"
                href="#social-network"
                className="title"
              >
                <span>Social Network Analysis</span>
              </a>
            </li>
            <li className="navitem">
              <a
                data-replace="Bagaimana TMS Membantu anda?"
                href="#kenapa"
                className="title"
              >
                <span>Bagaimana TMS Membantu anda?</span>
              </a>
            </li>
            <li className="navitem">
              <a data-replace="Layanan" href="#layanan" className="title">
                <span>Layanan</span>
              </a>
            </li>
            <li className="navitem">
              <a data-replace="Klien" href="#klien" className="title">
                <span>Klien</span>
              </a>
            </li>
            <li className="navitem">
              <a data-replace="Kontak" href="#kontak" className="title">
                <span>Kontak</span>
              </a>
            </li>
          </div>
        </div>
        <div className="logo">
          <a href="#hero">
            <img src="./assets/Logo/logo-tms.png" alt="logo-tms" width={50} />
          </a>
        </div>
      </div>
    </>
  );
};

export default Navbar;
