import React from "react";
import "./Pendahuluan.css";
import { useWindow } from "../../helpers/useWindow";

const Pendahuluan = () => {
  let layer = useWindow();

  let imageBackground;
  let pendahuluanImg;
  if (layer > 821) {
    imageBackground = "/assets/Image/baganctcorp.png";
    pendahuluanImg = "/assets/Image/pendahuluan.webp";
  } else {
    imageBackground = "/assets/Mobile/baganctcorp.png";
    pendahuluanImg = "/assets/Mobile/pendahuluan.png";
  }
  return (
    <div className="pendahuluan-container" id="pendahuluan">
      <div className="pendahuluan-container-top">
        <div className="pendahuluan-content">
          <div className="pendahuluan-text-container">
            <span className="pendahuluan-title">Halo</span>
            <span className="pendahuluan-desc">
              Trans Media Sosial (TMS) merupakan bagian dari korporasi media
              terbesar di Indonesia yaitu TRANSMEDIA, dengan lebih dari 6000
              karyawan aktif. TMS sangat berkompeten dalam memproduksi konten
              kreatif untuk berbagai platform media sosial. Kami membantu
              perusahaan/brand untuk terhubung dengan audiens melalui analisis
              kompetisi dan tren dengan penggunaan alat dan metode yang tepat.
            </span>
          </div>
          <div className="pendahuluan-image-container">
            <img
              className="pendahuluan-image"
              src={pendahuluanImg}
              alt="wanita"
            />
          </div>
        </div>
      </div>
      <div className="pendahuluan-container-bot">
        <div className="pendahuluan-bagan">
          <img src={imageBackground} alt="bagan-ct-corp" />
        </div>
        <div className="pendahuluan-text2">
          <span
            style={{ fontFamily: "Visby, sans-serif", marginBottom: "1rem" }}
            className="pendahuluan-title2"
          >
            We are TRANSMEDIA
          </span>
          <span className="pendahuluan-subtitle2">
            <span style={{ fontFamily: "Visby, sans-serif" }}>Kenapa TMS?</span>{" "}
            Karena kita tergabung
            <br /> dalam grup yang besar dengan ekosistem
            <br /> yang lengkap
          </span>
        </div>
        <img
          className="pendahuluan-image-pink"
          src="./assets/Image/circle-pink.svg"
          alt=""
        />
      </div>
    </div>
  );
};

export default Pendahuluan;
