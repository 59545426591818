import React from "react";
import "./Hero.css";
import { useWindow } from "../../helpers/useWindow";

const Hero = () => {
  let layer = useWindow();

  let imageBackground;
  if (layer >= 768) {
    imageBackground = "/assets/Image/hero.png";
  } else {
    imageBackground = "/assets/Mobile/hero.png";
  }

  return (
    <div className="hero">
      <img src={imageBackground} alt="" />
    </div>
  );
};

export default Hero;
