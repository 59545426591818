import React from "react";
import "./Ekosistem.css";
import { useWindow } from "../../helpers/useWindow";

const Ekosistem = () => {
  let layer = useWindow();

  let freeToAirImg;
  let websiteImg;
  let smartphoneImg;
  if (layer >= 768) {
    freeToAirImg = "/assets/Image/free-to-air.png";
    websiteImg = "/assets/Image/website.png";
    smartphoneImg = "/assets/Image/smartphone.png";
  } else {
    freeToAirImg = "/assets/Mobile/free-to-air.png";
    websiteImg = "/assets/Mobile/website.png";
    smartphoneImg = "/assets/Mobile/smartphone.png";
  }

  return (
    <div id="ekosistem" className="ekosistem-container">
      <span className="ekosistem-title">Ekosistem Komunikasi 360</span>
      <div className="ekosistem-image-container">
        <div className="ekosistem-image-content">
          <div>
            <img src={freeToAirImg} alt="" />
          </div>
          <div>
            <img src={websiteImg} alt="" />
          </div>
          <div>
            <img src={smartphoneImg} alt="" />
          </div>
        </div>
        <div className="ekosistem-image-content">
          <div className="ekosistem-image-desktop">
            <img src="/assets/Image/ekosistem2.png" alt="" />
          </div>
          <div className="ekosistem-image-mobile">
            <div className="ekosistem-image-mobile-list">
              <img src="/assets/Mobile/pasangan1.png" alt="" />
            </div>
            <div className="ekosistem-image-mobile-list">
              <img src="/assets/Mobile/pasangan2.png" alt="" />
            </div>
            <div className="ekosistem-image-mobile-list">
              <img src="/assets/Mobile/pasangan3.png" alt="" />
            </div>
            <div className="ekosistem-image-mobile-list">
              <img src="/assets/Mobile/pasangan4.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ekosistem;
