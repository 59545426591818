import React from "react";
import "./SocialNetworkAnalysis.css";
import { useWindow } from "../../helpers/useWindow";

const SocialNetworkAnalysis = () => {
  let layer = useWindow();

  let image;
  if (layer >= 820) {
    image = "/assets/Image/network-analysis.png";
  } else {
    image = "/assets/Mobile/network-analysis.png";
  }
  return (
    <div id="social-network" className="social-network-analysis-container">
      <div className="social-network-content">
        <span className="social-network-title">social network analysis</span>
      </div>
      <div className="social-network-content-image">
        <img src={image} alt="" />
      </div>
    </div>
  );
};

export default SocialNetworkAnalysis;
