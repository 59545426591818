import React from "react";
import "./TvProgram.css";
import { useWindow } from "../../helpers/useWindow";

const TvProgram = () => {
  let layer = useWindow();

  let tv1;
  let tv3;
  if (layer >= 768) {
    tv1 = "/assets/Image/musik.png";
    tv3 = "/assets/Image/kempot.png";
  } else {
    tv1 = "/assets/Mobile/musik.png";
    tv3 = "/assets/Mobile/kempot.png";
  }
  return (
    <>
      <div id="tv-program" className="tv-program-title-container">
        <span className="default-title">tv program</span>
      </div>
      <div className="tv-program-container">
        <img src={tv1} alt="" />
        <img src="./assets/Image/tulus.png" alt="" />
        <img src={tv3} alt="" />
      </div>
    </>
  );
};

export default TvProgram;
