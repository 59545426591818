import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="footer-content">
          <span className="footer-title">Trans Media Sosial @ 2022</span>
          <div className="sosmed-content">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://api.whatsapp.com/send?phone=08174999553"
            >
              <img
                src="/assets/Logo/logo-whatsapp.png"
                alt="logo-whatsapp"
                width={25}
              />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/transmediasosial/"
            >
              <img
                src="/assets/Logo/logo-ig.png"
                alt="logo-instagram"
                width={25}
              />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.tiktok.com/@creative.tms?is_from_webapp=1&sender_device=pc"
            >
              <img
                src="/assets/Logo/logo-tiktok.png"
                alt="logo-tiktok"
                width={25}
              />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/company/transmediasosial/mycompany/"
            >
              <img
                src="/assets/Logo/logo-linkedin.png"
                alt="logo-linkedin"
                width={25}
              />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/transmedsos"
            >
              <img
                src="/assets/Logo/logo-twitter.png"
                alt="logo-twitter"
                width={25}
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
