import React from "react";
import "./Offair.css";
import { useWindow } from "../../helpers/useWindow";

const Offair = () => {
  let layer = useWindow();

  let offair1;
  let offair2;
  let offair3;
  if (layer >= 768) {
    offair1 = "/assets/Image/offair1.png";
    offair2 = "/assets/Image/offair2.png";
    offair3 = "/assets/Image/offair3.png";
  } else {
    offair1 = "/assets/Mobile/offair1.png";
    offair2 = "/assets/Mobile/offair2.png";
    offair3 = "/assets/Mobile/offair3.png";
  }
  return (
    <>
      <div id="offair" className="offair-title-container">
        <span className="default-title">offair activations & exhibition</span>
      </div>
      <div className="offair-container">
        <img src={offair1} alt="" />
        <img src={offair2} alt="" />
        <img src={offair3} alt="" />
      </div>
    </>
  );
};

export default Offair;
