import React from "react";
import "./Portfolio.css";
import { useWindow } from "../../helpers/useWindow";

const Portfollio = () => {
  let layer = useWindow();

  let image;
  if (layer >= 821) {
    image = "/assets/Image/shape-gradient.png";
  } else {
    image = "/assets/Mobile/shape-gradient.png";
  }
  return (
    <div id="sosmed-content" className="sosmed-all-container">
      <div className="sosmed-content-prod-title">
        <span>social </span>
        <span>media </span>
        <span>content </span>
        <span>production</span>
      </div>
      <div className="sosmed-content-prod-container">
        <div id="portfolio" className="portfolio-container">
          <img src={image} alt="" />
        </div>
        <div className="portfolio-container-right">
          <div className="portfolio-content">
            <div className="portfolio-content-logo">
              <img src="/assets/Logo/logo-mega.webp" alt="" />
            </div>
            <div className="portfolio-content-list">
              <a
                className="btn"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/p/CfJn8jbgjVp/"
              >
                <img src="/assets/Image/content-mega1.webp" alt="" />
              </a>
              <a
                className="btn"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/p/CgDxPIkBW-1/"
              >
                <img src="/assets/Image/content-mega2.webp" alt="" />
              </a>
              <a
                className="btn"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/p/CgT-FAjhr5D/"
              >
                <img src="/assets/Image/content-mega3.webp" alt="" />
              </a>
            </div>
          </div>
          <div className="portfolio-content">
            <div className="portfolio-content-logo2">
              <img src="/assets/Logo/logo-bpsdmi.webp" alt="" />
            </div>
            <div className="portfolio-content-list">
              <a
                className="btn"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/p/CJ8BOAaFEy0/?igshid=YmMyMTA2M2Y="
              >
                <img src="/assets/Image/content-bpsdmi1.webp" alt="" />
              </a>
              <a
                className="btn"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/p/CJ-vq9Vlyqr/?igshid=YmMyMTA2M2Y="
              >
                <img src="/assets/Image/content-bpsdmi2.webp" alt="" />
              </a>
              <a
                className="btn"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/p/CKXw6gWFj22/?igshid=YmMyMTA2M2Y="
              >
                <img src="/assets/Image/content-bpsdmi3.webp" alt="" />
              </a>
            </div>
          </div>
          <div className="portfolio-content">
            <div className="portfolio-content-logo2">
              <img src="/assets/Logo/logo-satubsd.webp" alt="" />
            </div>
            <div className="portfolio-content-list">
              <a
                className="btn"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/p/CVz-R5OLHj-/?igshid=YmMyMTA2M2Y="
              >
                <img src="/assets/Image/content-satubsd1.webp" alt="" />
              </a>
              <a
                className="btn"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/p/CViBrZ5hGyP/?igshid=YmMyMTA2M2Y="
              >
                <img src="/assets/Image/content-satubsd2.webp" alt="" />
              </a>
              <a
                className="btn"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/p/CT6tQSBJoue/?igshid=YmMyMTA2M2Y="
              >
                <img src="/assets/Image/content-satubsd3.webp" alt="" />
              </a>
            </div>
          </div>
          <div className="portfolio-content">
            <div className="portfolio-content-logo2">
              <img src="/assets/Logo/logo-majalengka.webp" alt="" />
            </div>
            <div className="portfolio-content-list">
              <a
                className="btn"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/p/CO2IQoqNU9v/"
              >
                <img src="/assets/Image/content-majalengka1.webp" alt="" />
              </a>
              <a
                className="btn"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/p/COfHLaGNJ_8/"
              >
                <img src="/assets/Image/content-majalengka2.webp" alt="" />
              </a>
              <a
                className="btn"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/p/CNrBnIUFn5j/"
              >
                <img src="/assets/Image/content-majalengka3.webp" alt="" />
              </a>
            </div>
          </div>
          <div className="portfolio-content">
            <div className="portfolio-content-logo2">
              <img src="/assets/Logo/logo-transmart.png" alt="" />
            </div>
            <div className="portfolio-content-list">
              <a
                className="btn"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/p/CKWQLpahumI/"
              >
                <img src="/assets/Image/content-transmart1.png" alt="" />
              </a>
              <a
                className="btn"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/p/CR_BziRhgEt/"
              >
                <img src="/assets/Image/content-transmart2.png" alt="" />
              </a>
              <a
                className="btn"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/p/CL_bITNAfXF/"
              >
                <img src="/assets/Image/content-transmart3.png" alt="" />
              </a>
            </div>
          </div>
          <div className="portfolio-content">
            <div className="portfolio-content-logo2">
              <img src="/assets/Logo/logo-trb.png" alt="" />
            </div>
            <div className="portfolio-content-list">
              <a
                className="btn"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/p/CR5S0_qBZs7/"
              >
                <img src="/assets/Image/content-trb1.png" alt="" />
              </a>
              <a
                className="btn"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/p/CPe59rfBr3s/"
              >
                <img src="/assets/Image/content-trb2.png" alt="" />
              </a>
              <a
                className="btn"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/p/CZf9DtqtY6e/"
              >
                <img src="/assets/Image/content-trb3.png" alt="" />
              </a>
            </div>
          </div>
          <div className="portfolio-content">
            <div className="portfolio-content-logo2">
              <img src="/assets/Logo/logo-fh.png" alt="" />
            </div>
            <div className="portfolio-content-list">
              <a
                className="btn"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/p/Cg3LeQUptZq/"
              >
                <img src="/assets/Image/content-fh1.png" alt="" />
              </a>
              <a
                className="btn"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/p/CfF_uXFtXsm/"
              >
                <img src="/assets/Image/content-fh2.png" alt="" />
              </a>
              <a
                className="btn"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/p/CaOTYOXBoPt/"
              >
                <img src="/assets/Image/content-fh3.png" alt="" />
              </a>
            </div>
          </div>
          <div className="portfolio-content">
            <div className="portfolio-content-logo2">
              <img src="/assets/Logo/logo-tsm.png" alt="" />
            </div>
            <div className="portfolio-content-list">
              <a
                className="btn"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/p/CfV9izTBVZC/"
              >
                <img src="/assets/Image/content-tsm1.png" alt="" />
              </a>
              <a
                className="btn"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/p/ChKGksIJpGA/"
              >
                <img src="/assets/Image/content-tsm2.png" alt="" />
              </a>
              <a
                className="btn"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/p/CeyEP08jSo7/"
              >
                <img src="/assets/Image/content-tsm3.png" alt="" />
              </a>
            </div>
          </div>
          <div className="portfolio-content">
            <div className="portfolio-content-logo2">
              <img src="/assets/Logo/logo-berbuatbaik.png" alt="" />
            </div>
            <div className="portfolio-content-list">
              <a
                className="btn"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/p/CY-d1b1pY7p/"
              >
                <img src="/assets/Image/content-berbuatbaik1.png" alt="" />
              </a>
              <a
                className="btn"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/p/Cf_RIVoJeR0/"
              >
                <img src="/assets/Image/content-berbuatbaik2.png" alt="" />
              </a>
              <a
                className="btn"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/p/Cc975cQvsiy/"
              >
                <img src="/assets/Image/content-berbuatbaik3.png" alt="" />
              </a>
            </div>
          </div>
          <div className="portfolio-content">
            <div className="portfolio-content-logo2">
              <img src="/assets/Logo/logo-alluthfi.png" alt="" />
            </div>
            <div className="portfolio-content-list">
              <a
                className="btn"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/p/CcR8lRLLJQs/"
              >
                <img src="/assets/Image/content-alluthfi1.png" alt="" />
              </a>
              <a
                className="btn"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/p/CcfTX8mrfNg/"
              >
                <img src="/assets/Image/content-alluthfi2.png" alt="" />
              </a>
              <a
                className="btn"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/p/Cccx-bJLph5/"
              >
                <img src="/assets/Image/content-alluthfi3.png" alt="" />
              </a>
            </div>
          </div>
          <div className="portfolio-content">
            <div className="portfolio-content-logo2">
              <img src="/assets/Logo/logo-ror.png" alt="" />
            </div>
            <div className="portfolio-content-list">
              <a
                className="btn"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/p/CfBER_TpkpW/"
              >
                <img src="/assets/Image/content-ror1.png" alt="" />
              </a>
              <a
                className="btn"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/p/CeTMok7JBrh/"
              >
                <img src="/assets/Image/content-ror2.png" alt="" />
              </a>
              <a
                className="btn"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/p/Cbwp59Bp5M-/"
              >
                <img src="/assets/Image/content-ror3.png" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfollio;
