import React from "react";
import "./Kontak.css";

const Kontak = () => {
  return (
    <div id="kontak" className="kontak-container">
      <div className="kontak-title">
        <span>Kontak</span>
      </div>
      <div className="kontak-subtitle">
        <span>Halo dari TMS +62 823-0823-9702</span>
        <span>Whatsapp +62 817-4999-553</span>
      </div>
      <div className="kontak-image">
        <img src="./assets/Image/kontak-image.webp" alt="" />
      </div>
    </div>
  );
};

export default Kontak;
