import React from "react";
import "./Klien.css";
import { useWindow } from "../../helpers/useWindow";

const Klien = () => {
  let layer = useWindow();

  let klienBundle;
  let peopleImage;
  if (layer >= 768) {
    klienBundle = "/assets/Image/klien-bundle.png";
    peopleImage = "/assets/Image/people.png";
  } else {
    klienBundle = "/assets/Mobile/klien-bundle.png";
    peopleImage = "/assets/Mobile/people.png";
  }
  return (
    <div id="klien" className="klien-container">
      <div className="klien-content-container">
        <div className="klien-ijo"></div>
        <div className="klien-bundle">
          <img src={klienBundle} alt="" />
        </div>
        <div className="klien-people">
          <img src={peopleImage} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Klien;
