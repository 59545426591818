import React from "react";
import "./Kenapa.css";
import { useWindow } from "../../helpers/useWindow";

const Kenapa = () => {
  let layer = useWindow();

  let image;
  if (layer >= 821) {
    image = "/assets/Image/kenapa2.png";
  } else {
    image = "/assets/Mobile/kenapa2.png";
  }
  return (
    <div className="kenapa-container" id="kenapa">
      <div className="kenapa-title-container">
        <span className="kenapa-title">Bagaimana Trans Media Sosial</span>
        <span className="kenapa-title">Membantu Anda?</span>
        <div className="kenapa-desc">
          <span>
            Memperluas <span id="pbold">Promosi</span> Perusahaan
            <ul className="kenapa-list">
              <li>Optimalisasi penggunaan platform promosi gratis</li>
              <li>Optimalisasi penggunaan platform promosi berbayar</li>
              <li>Optimalisasi analisa dan strategi</li>
            </ul>
            <span>
              Meningkatkan <span id="pbold">Interaksi</span> dengan Pelanggan /
              Fans terhadap brand
            </span>
            <ul className="kenapa-list">
              <li>
                Optimalisasi produksi konten untuk sosial media dan media
                promosi
              </li>
              <li>
                Optimalisasi keikutsertaan key opinion leader dan influencer
              </li>
              <li>
                Memperkuat analisa dari aspek konten, audience, kompetitor, dan
                tren
              </li>
            </ul>
            <span>
              Memperkenalkan <span id="pbold">Produk</span> Baru
            </span>
            <ul className="kenapa-list">
              <li>
                Memperkuat dan mengevaluasi konten yang sesuai dengan produk
              </li>
              <li>Visualisasi konten yang menarik </li>
              <li>
                Kampanye dengan tema khusus/cara komunikasi berdasarkan analisa
                audience
              </li>
            </ul>
            <span>
              <span id="pbold">Menganalisis</span> Audience
            </span>
            <ul className="kenapa-list">
              <li>
                Menganalisa percakapan secara global di industri sesuai dengan
                brand
              </li>
              <li>
                Menganalisa demografi dan perilaku audience di digital yang
                sesuai target brand
              </li>
              <li>Data dan insight lainnya</li>
            </ul>
          </span>
        </div>
      </div>
      <div className="kenapa-image">
        <img src={image} alt="" />
      </div>
    </div>
  );
};

export default Kenapa;
