import React from "react";
import "./Layanan.css";
import { useWindow } from "../../helpers/useWindow";

const Layanan = () => {
  let layer = useWindow();

  let image;
  if (layer >= 821) {
    image = "/assets/Image/women-line.png";
  } else {
    image = "/assets/Mobile/women-line.png";
  }
  return (
    <div id="layanan" className="layanan-container">
      <div className="layanan-title">
        <span>Layanan</span>
      </div>
      <div className="layanan-content-container">
        <div className="layanan-content">
          <span className="layanan-content-header">
            &bull; social media communication
          </span>
          <br />
          <div className="layanan-content-desc">
            <div className="layanan-content-detail">
              <span>
                <span className="layanan-bold">
                  Optimaliasi paparan dari brand melalui sosial media
                </span>{" "}
                lewat analisa, kampanye digital, dan strategi komunikasi untuk
                berinteraksi dengan target audience Anda.
              </span>
            </div>
            <span>&bull; Perencanaan dan strategi sosial media</span>
            <br />
            <span>&bull; Produksi konten sosial media</span>
            <br />
            <span>&bull; Iklan digital dan sosial media</span>
            <br />
            <span>&bull; Pelaporan sosial media</span>
            <br />
          </div>
        </div>
        <div className="layanan-content">
          <span className="layanan-content-header">
            &bull; social media monitoring
          </span>
          <br />
          <div className="layanan-content-desc">
            <div className="layanan-content-detail">
              <span>
                Pantau kondisi brand Anda di mata audiens, bagaimana
                sentimennya, ekuitas brand, persepsi audiens serta analisis
                target audiens untuk{" "}
                <span className="layanan-bold">
                  meningkatkan nilai brand Anda.
                </span>
              </span>
            </div>
            <span>&bull; Analisa performa sosial media</span>
            <br />
            <span>&bull; Analisa hubungan jejaring sosial</span>
            <br />
            <span>&bull; Penanganan crisis</span>
            <br />
            <span>&bull; Analisa kompetitor</span>
            <br />
            <span>&bull; Sentimen dan potensial isu</span>
            <br />
            <span>&bull; Tren industri</span>
            <br />
          </div>
        </div>
      </div>
      <div className="layanan-content-container layanan-content-web-container">
        <div className="layanan-content-web-dev">
          <span className="layanan-content-header">&bull; web development</span>
          <br />
          <div className="layanan-content-desc">
            <div className="layanan-content-detail layanan-content-detail2">
              <span>
                Pikat pelanggan Anda dengan memberi mereka{" "}
                <span className="layanan-bold">
                  informasi yang berguna tentang bagaimana Anda dapat memecahkan
                  masalah
                </span>{" "}
                mereka melalui website, kemudian munculkan website Anda di
                halaman pertama pencarian google.
              </span>
            </div>
            <span>&bull; UI & UX Design</span>
            <br />
            <span>&bull; Frontend Development</span>
            <br />
            <span>&bull; Backend Development</span>
            <br />
            <span>&bull; IT Infrastruktur</span>
            <br />
            <span>&bull; Search Engine Optimization</span>
          </div>
        </div>
        <div className="layanan-content">
          <span className="layanan-content-header">
            &bull; digital ads & social buzz
          </span>
          <br />
          <div className="layanan-content-desc">
            <div className="layanan-content-detail">
              <span>
                Mulai dari iklan media sosial sampai ke optimalisasi pencarian
                pada search engine. Kami akan membantu anda untuk menjangkau
                audiens yang lebih besar!
              </span>
            </div>
            <span>&bull; Iklan meta (Facebook & Instagram)</span>
            <br />
            <span>&bull; Iklan platform Google</span>
            <br />
            <span>&bull; Buzzer dan sentimen positif</span>
          </div>
        </div>
      </div>
      <div className="layanan-content-container-last">
        <div className="layanan-content">
          <span className="layanan-content-header">&bull; content seeding</span>
          <br />
          <div className="layanan-content-desc">
            <div className="layanan-content-detail layanan-content-detail3">
              <span>
                Membangun citra perusahaan melalui pemberitaan yang
                berkelanjutan. Media online ataupun cetak masih sangat relevan
                untuk menjadi alat komunikasi kepada publik karena memiliki
                pembacanya sendiri, khususnya Gen Y & Gen X.
              </span>
            </div>
            <span>&bull; Pembuatan press rilis</span>
            <br />
            <span>&bull; Daftar preferensi media</span>
            <br />
            <span>&bull; Pendistribusian media</span>
            <br />
            <span>&bull; Pelaporan hasil media</span>
          </div>
        </div>
      </div>
      <img className="layanan-content-image" src={image} alt="" />
    </div>
  );
};

export default Layanan;
