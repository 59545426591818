import React from "react";
import "./WebDevelopment.css";

const WebDevelopment = () => {
  return (
    <div id="web-dev" className="web-dev-container">
      <div className="web-dev-title">
        <span>web development</span>
      </div>
      <div className="web-dev-content">
        <a
          // className="btn"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.ademsari.co.id/"
        >
          <img src="/assets/Image/website-ademsari.webp" alt="" />
        </a>
        <a
          // className="btn"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.stella-airfreshener.com/"
        >
          <img src="/assets/Image/website-stella.webp" alt="" />
        </a>
      </div>
      <div className="web-dev-content">
        <a
          // className="btn"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.transluxuryyacht.com/"
        >
          <img src="/assets/Image/website-tly.webp" alt="" />
        </a>
        <a
          // className="btn"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.saniter.co.id/"
        >
          <img src="/assets/Image/website-saniter.webp" alt="" />
        </a>
      </div>
      <div className="web-dev-content">
        <a
          // className="btn"
          target="_blank"
          rel="noopener noreferrer"
          href="https://allobank.com"
        >
          <img src="/assets/Image/website-allobank.webp" alt="" />
        </a>
      </div>
    </div>
  );
};

export default WebDevelopment;
